import React, { useState, useContext } from "react";
import "../App.css";
import { Icon } from "@anchor/react-components";
import { TableContext } from "../context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DeleteConfirmation = ({ onDelete, setShowCommit, value }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    table1,
    setTable1,
    deletedRows,
    setDeletedRows,
    graphData,
    data,
    setData,
    appData,
    relationshipCategorySelected,
    isRetired, setIsRetired,retiredApps
  } = useContext(TableContext);
  const handleDeleteClick = () => {
    if (relationshipCategorySelected.value == "app_to_server") {
      setShowConfirmation(
        !data.some(
          (subArray) =>
            subArray.includes(value[0]) && subArray.includes(value[2])
        ) && !['Decommissioned', 'Stolen', 'retired', 'Retired'].includes(value[5]) && !['Not mapped'].includes(value[2]) && !retiredApps?.includes(value[0])
      );
    } else if (relationshipCategorySelected.value == "app_to_app") {
      setShowConfirmation(
        !appData.some((subArray) => subArray[7] == value[4])
      );
    } else if (relationshipCategorySelected.value == "server_to_app") { 
      setShowConfirmation(
        // !data.some(
        //   (subArray) =>
        //     subArray.includes(value[0]) && subArray.includes(value[2])
        // ) && 
        !(['Decommissioned', 'Stolen', 'retired', 'Retired'].includes(value[1]) || 'Orphan Server'.includes(value[7]))
      );
    } else {
      toast.error("Please select a relationship category", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleConfirmDelete = () => {
    // Call the onDelete function to perform the actual delete action
    onDelete();
    // Hide the confirmation popup
    setShowConfirmation(false);
  };
  const handleCancelDelete = () => {
    // Hide the confirmation popup
    setShowConfirmation(false);
  };
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Icon
        name="trash"
        onClick={isRetired ? null : handleDeleteClick}
        id="icon"
        // style={data.some((subArray) => subArray.includes(value[0]) && subArray.includes(value[2])) ? { cursor: "not-allowed" } : null}
        style={
          relationshipCategorySelected.value === "server_to_app" &&
            ['Decommissioned', 'Stolen', 'retired', 'Retired'].includes(value[1]) || 'Orphan Server'.includes(value[7])
            ? { cursor: "not-allowed" }
            : relationshipCategorySelected.value == "app_to_server"
            ? isRetired || data.some(
                (subArray) =>
                  subArray.includes(value[0]) && subArray.includes(value[2])
              ) || ['Decommissioned', 'Stolen', 'retired', 'Retired'].includes(value[5]) || ['Not mapped'].includes(value[2]) || retiredApps?.includes(value[0])
              ? { cursor: "not-allowed" }
              : null
            : relationshipCategorySelected.value == "app_to_app"
            ? isRetired || appData.some((subArray) =>  subArray[7] == value[4])
              ? { cursor: "not-allowed" }
              : null
            : null
        }
      />
      {showConfirmation && (
        <div className="confirmation-popup">
          <p>Are you sure you want to delete?</p>
          <div className="centered-elements">
            <button className="yes" onClick={handleConfirmDelete}>
              Yes
            </button>
            <button className="no" onClick={handleCancelDelete}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeleteConfirmation;
