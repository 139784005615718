import {
  ReactFlow,
  MiniMap,
  Controls,
  MarkerType,
  SimpleBezierEdge,
  Background,
} from "reactflow";
import { React, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { TableContext } from "../context";

import { AiOutlineExpandAlt } from "react-icons/ai";

import "reactflow/dist/style.css";
import Popup from "./Popup";

// const initialNodes = [
//   { id: '1', position: { x: 200, y: 0 }, table5: { label: 'gcss' }, },
//   { id: '2', position: { x: 600, y: 0 }, table5: { label: 'clue' } },
//   { id: '3', position: { x: 200, y: 200 }, table5: { label: 'sap' } },
//   { id: '4', position: { x: 600, y: 200 }, table5: { label: 'sap financial' } },
//   { id: '5', position: { x: 400, y: 100 }, table5: { label: 'MEPC' } },

// ];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '5' },{ id: 'e1-3', source: '2', target: '5' },{ id: 'e1-4', source: '5', target: '3' },{ id: 'e2-5', source: '5', target: '4' } ];

export default function Tree() {
  const reactFlowRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { table5, applicationName } = useContext(TableContext);
  const initialNodes = [];
  const initialEdges = [];

  // Initialize variables to hold Y positions of upstream and downstream nodes
  let upstreamY = 0;
  let downstreamY = 0;
  let upstreamCount = 0;
  let downstreamCount = 0;
  
  table5.forEach((values, index) => {
    const label = values[1];
  
    if (label === "downstream") {
      downstreamY += index;
      downstreamCount++;
    } else {
      upstreamY += index;
      upstreamCount++;
    }
  });
  
  const centralNodeY = 300;
  const centralNodeId = "0";
  let downstreamindex = 0;
  let upstreamindex = 0;
  let maxXPosition = 0;
  
  table5.forEach((values, index) => {
    const label = values[1];
    let positionX;
  
    if (label === "downstream") {
      positionX =
        (downstreamindex % 2 === 0 ? 1 : -1) *
        Math.floor(downstreamindex / 2) *
        200; // Increased multiplier here
      downstreamindex++;
      const position = {
        x: positionX,
        y: 500,
      };
      const id = `${index + 1}`;
      initialNodes.push({ id, position, data: { label: values[2] } });
    }
  
    if (label === "upstream") {
      positionX =
        (upstreamindex % 2 === 0 ? 1 : -1) *
        Math.floor(upstreamindex / 2) *
        200; // Increased multiplier here
      upstreamindex++;
      const position = {
        x: positionX,
        y: 100,
      };
      const id = `${index + 1}`;
      initialNodes.push({ id, position, data: { label: values[2] } });
    }
  
    if (Math.abs(positionX) > maxXPosition) {
      maxXPosition = Math.abs(positionX);
    }
  
    initialNodes.push({
      id: centralNodeId,
      position: { x: 0, y: centralNodeY },
      data: { label: applicationName },
      style: { fontWeight: "bold" },
    });
  
    const id = `${index + 1}`;
  
    initialEdges.push({
      id: `e0-${id}`,
      source: label === "downstream" ? centralNodeId : id,
      target: label === "downstream" ? id : centralNodeId,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        fill: "#000000",
        strokeWidth: 4,
      },
      style: { stroke: label === "downstream" ? "#0073AB" : "#B5E0F5" },
    });
  });

  // console.log("Initial Nodes:", initialNodes);
  // console.log("Initial Edges:", initialEdges);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    document
      .querySelectorAll('[rel="noopener noreferrer"]')
      ?.forEach(function (element) {
        element.style.display = "none";
        console.log("Tree table5", table5);
        var fit = document.getElementsByClassName(
          "react-flow__controls-button react-flow__controls-fitview"
        );
        if (fit) {
          console.log("fit", fit);

          // fit.click();
        }
        if (reactFlowRef.current) {
          // reactFlowRef.current.;
        }
      });
  }, []);
  return (
    <>
      {table5?.length > 0 && (
        <div style={{ width: "100%", height: "400px", background: "#F5F5F5" }}>
         <span>
          <AiOutlineExpandAlt
            title="Expand"
            size={25}
            style={{
              float: "right",
              margin: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenPopup();
            }}
          />
           </span>

          <ReactFlow
            style={{
              background: "#F5F5F5",
            }}
            fitView
            nodes={initialNodes}
            edges={initialEdges}
            ref={reactFlowRef}
          >
            <Background color="#F5F5F5" />
            <Controls showInteractive={false} />
          </ReactFlow>
          {isPopupOpen && (
            <Popup onClose={() => setIsPopupOpen(false)}>
              <ReactFlow
                fitView
                nodes={initialNodes}
                edges={initialEdges}
                ref={reactFlowRef}
                style={{ background: "#F5F5F5", padding: "0px", margin: "0px" }}
              >
                <Background color="#F5F5F5" />
                <Controls showInteractive={false} />
              </ReactFlow>
            </Popup>
          )}
        </div>
      )}
    </>
  );
}
