import React, { useEffect, useState, useContext,useLayoutEffect } from "react";
import Table3 from "./Table3";
import { TableContext } from "../context";
import { toast } from "react-toastify";

/**
 * Renders the ApproveChanges component.
 *
 * @param {Object} props - The component props.
 * @param {function} props.setProgress - The function to set the progress.
 * @returns {JSX.Element} The rendered ApproveChanges component.
 */
export default function ApproveChanges(props) {
  const [table1, setTable1] = useState([]);
  // Context for the graph data
  const { graphData, setGraphData, fetchUserDetails,fetchWithTimeout } =
    useContext(TableContext);
    useLayoutEffect(() => {
      if(graphData?.displayName!==undefined)
        {
          getData();
  
        }
    }, [graphData]);
  // Function for fetching data from the server
  const getData = async () => {
    // Set progress to 10
    props.setProgress(10);
    try {
      // Fetch data from the server
      await fetchWithTimeout(
        `https://prod-cmdbbackend.azurewebsites.net/approvechanges?ciowner=${encodeURIComponent(graphData?.displayName)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Set the fetched data to the state
          setTable1(data);
          // Set progress to 70
          props.setProgress(70);
        });
      // Set progress to 100
      props.setProgress(100);
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Handle error
    }
  };
  return (
    <div>
      {
        <Table3
        table1={table1}
          setProgress={props.setProgress}
          FilterIndex={0}
          FilterIndex2={5}
          Header1="App Name"
          Header2="Related To"
          Header3="Action"
          Header4="RequestedBy"
          Header5="App Owner"
          Header6="Relationship Category"
          getData={getData}
        />
      }
    </div>
  );
}
