import React, { useEffect, useContext, useState, useRef } from "react";
import "./Certification.css";
import {
  Button,
  Icon,
  LoadingIndicator,
  Accordian,
} from "@anchor/react-components";
import { Tooltip } from "react-tooltip";
import { TableContext } from "../context";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FcApproval } from "react-icons/fc";
import { FaExclamationCircle,FaCheckCircle } from "react-icons/fa";

function Certification(props) {
  const [readOnly, setReadOnly] = useState(false);
  const [showbuttons, setshowbuttons] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [lastUpdatedField, setLastUpdatedField] = useState(null);

  const [dropdownStates, setDropdownStates] = useState({
    owned_by: true,
    managed_by: true,
    u_engineering_manager: true,
    u_sr_engineering_manager: true,
    u_engineering_director: true,
  });
  const navigate = useNavigate();

  const {
    graphData,
    setapplicationName,
    isciadmingrp,
    setisciadmingrp,
    certifyapplicationName,
    setcertifyapplicationName,
    setautosearch,
    ownershipdata,
    setownershipdata,
    disableCertify1,
    setDisableCertify1,
    certificationSuccessful1,
    setCertificationSuccessful1,
    ownershipTooltip,
    setownershipTooltip,
    certifypercentage,
    setcertifypercentage,
    showTooltip1,
    setShowTooltip1,
    appenv,
    setappenv,
    relationcertified,
    setrelationcertified,
    ownershipcertified,
    setownershipcertified,
    autosearch1,
    setautosearch1,
    fetchOwnership,
    showTooltip2,
    setShowTooltip2,
    setRelationshipCategorySelected,
    relationshipCategory,
    fetchWithTimeout,
    disableCertify1apptoapp,
    setDisableCertify1apptoapp,
    showTooltip2apptoapp,
    setShowTooltip2apptoapp,
    showTooltip1apptoapp,
    setShowTooltip1apptoapp,
    relationcertifiedapptoapp,
    setrelationcertifiedapptoapp,
    selectedSuggestion, 
    setSelectedSuggestion,
    macDetails,
    setMacDetails,
  } = useContext(TableContext);
  const [tempData, setTempData] = useState({ ...ownershipdata });
  const [editable, setEditable] = useState(false);
  const handleAccordionclick = (e) => {
    setIsViewOpen(!isViewOpen);
    // //console.log("e",e.target)
    var acc = document.getElementsByClassName("accordion");
    var i;
    acc[0].classList.toggle("active");
    var panel = document.getElementsByClassName("panel")[0];
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.marginTop = "0px";
    } else {
      panel.style.maxHeight = panel.scrollHeight + 30 + "px";
      panel.style.marginTop = "10px";
    }
  };
  async function getOwnershipInfo(application) {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/getownershipinfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ applicationName: application }),
        }
      );
      // props.setProgress(40);
      const result = await response.json();
      // //console.log("ownership data", result);
      // const data = {
      //   managed_by: { displayValue: "", value: "" },
      //   owned_by: { displayValue: "", value: "" },
      //   sys_id: "",
      //   u_engineering_manager: { displayValue: "", value: "" },
      //   u_sr_engineering_manager: { displayValue: "", value: "" },
      //   u_engineering_director: { displayValue: "", value: "" },
      // };
      // Object.keys(result).forEach((key) => {
      //   const value = result[key];
      //   if (key === "owned_by") {
      //     data.owned_by.name = value;
      //   } else if (key === "managed_by") {
      //     data.managed_by.name = value;
      //   } else if (key === "u_engineering_manager") {
      //     data.u_engineering_manager.name = value;
      //   } else if (key === "u_sr_engineering_manager") {
      //     data.u_sr_engineering_manager.name = value;
      //   } else if (key === "u_engineering_director") {
      //     data.u_engineering_director.name = value;
      //   } else if (key === "sys_id") {
      //     data.sys_id = value;
      //   }
      // });
      await setownershipdata(result);
      if (autosearch1) {
        // inputRef.current.click();
        //console.log(inputRef.current);
      }
      setReadOnly(false);

      //console.log("data", ownershipdata.managed_by.displayValue);

      // props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  const autoSearchRefresh = async () => {
    props.setProgress(0);
    await getOwnershipInfo(certifyapplicationName);
    props.setProgress(20);
    await showRelationshipCertifyButton(certifyapplicationName);
    await showAppToAppCertifyButton(certifyapplicationName);
    props.setProgress(40);
    await getCertifyTooltip(certifyapplicationName);
    props.setProgress(60);
    await getCertificationPercentage(certifyapplicationName);
    props.setProgress(80);
    await setautosearch1(false);
    props.setProgress(100);
  };

  useEffect(() => {
    if (autosearch1) {
      autoSearchRefresh();
    }
  }, []);

  useEffect(() => {
    const values = [
      ownershipdata.managed_by.displayValue,
      ownershipdata.owned_by.displayValue,
      ownershipdata.u_engineering_manager.displayValue,
      ownershipdata.u_sr_engineering_manager.displayValue,
      ownershipdata.u_engineering_director.displayValue,
    ];
    // //console.log("values", values);
    setisciadmingrp(values.includes(graphData?.displayName));
    // //console.log("isciadmingrp", isciadmingrp);
  }, [ownershipdata, graphData?.displayName]);

  const handleapplicationNameChange = async (event) => {
    // //console.log(event.target.value.length);
    setcertifyapplicationName(event.target.value);
    const searchBox = document.getElementById("search-bar2");
    const suggestionBox = document.getElementById("suggestion-container2");
    const searchTerm = searchBox.value;
    // //console.log(searchTerm);

    // suggestionBox.innerHTML = "";
    // Make an AJAX request to fetchWithTimeout application names from the server
    if (event.target.value.length >= 3) {
      await fetchWithTimeout(

        `https://prod-cmdbbackend.azurewebsites.net/getappsforcertify?applicationName=${encodeURIComponent(searchTerm)}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Clear previous suggestions
          suggestionBox.innerHTML = "";
          // Display a maximum of 20 suggestions
          const maxSuggestions = 20;
          for (let i = 0; i < data.length && i < maxSuggestions; i++) {
            const suggestionItem = document.createElement("div");
            suggestionItem.classList.add("suggestion-item");
            suggestionItem.textContent = data[i];
            suggestionItem.addEventListener("click", async function () {
              await setcertifyapplicationName(data[i]);
              searchBox.style.borderBottomLeftRadius = "5px";
              searchBox.style.borderBottomRightRadius = "5px";
              suggestionBox.style.display = "none";
              props.setProgress(10); // Hide the suggestion box on click
              await getOwnershipInfo(data[i]);
              await showRelationshipCertifyButton(data[i]);
              props.setProgress(60);
              await getCertificationPercentage(data[i]);
              await showAppToAppCertifyButton(data[i]);
              props.setProgress(80);
              await getCertifyTooltip(data[i]);
              await getMacDetails(data[i]);
              props.setProgress(100);
              // await isciadmin();
            });
            suggestionBox.appendChild(suggestionItem);
          }
          if (data.length > 0 && searchTerm.length >= 3) {
            suggestionBox.style.display = "block";
            searchBox.style.borderBottomLeftRadius = "0%";
            searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
          } else {
            suggestionBox.style.display = "none";
            searchBox.style.borderBottomLeftRadius = "5px";
            searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    if (event.target.value.length == 0) {
      //console.log("No data");
      suggestionBox.innerHTML = "";
      suggestionBox.style.display = "none";
      searchBox.style.borderBottomLeftRadius = "5px";
      searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
    }
  };
  const handleAppToAppRelationshipCertify = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/certify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: certifyapplicationName,
          logged_in_user: graphData?.displayName,
          relationship_category: "app_to_app",
        }),
      });
      const data = await response.json();
      props.setProgress(50);

      if (data.message === "Certified") {
        await fetchOwnership();
        await showAppToAppCertifyButton(certifyapplicationName);
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableCertify1apptoapp(true);
        props.setProgress(70);

        await getCertificationPercentage(certifyapplicationName);
        await showAppToAppCertifyButton(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleRelationshipCertify = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/certify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: certifyapplicationName,
          logged_in_user: graphData?.displayName,
          relationship_category: "app_to_server",
        }),
      });
      const data = await response.json();
      props.setProgress(50);

      if (data.message === "Certified") {
        await fetchOwnership();
        await showRelationshipCertifyButton(certifyapplicationName);
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableCertify1(true);
        props.setProgress(70);

        await getCertificationPercentage(certifyapplicationName);
        await showRelationshipCertifyButton(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    if (readOnly) {
      const timeout = setTimeout(() => {
        handleiconclick();
        setLastUpdatedField(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [tempData]);
  let timeout = null;
  const handleChange = (e, category) => {
    setTempData({
      ...tempData,
      [e.target.name]: {
        displayValue: e.target.value,
        value: "",
        email: "", // Adding sysid as empty string as per the schema
      },
    });
    setLastUpdatedField(e.target.name);
    // if (timeout) clearTimeout(timeout);

    // timeout = setTimeout(() => {
    //   handleiconclick(e, category);
    // }, 500); // 300ms delay
  };
  const handleCrossClick = (e) => {
    document.getElementsByName(e.target.id)[2].style.display = "none";
    document.getElementsByName(e.target.id)[0].style.borderBottomLeftRadius =
      "5px";
    document.getElementsByName(e.target.id)[1].style.borderBottomRightRadius =
      "5px";
    setDropdownStates({
      ...dropdownStates,
      [e.target.id]: true,
    });
  };

  const getMacDetails = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        `https://prod-cmdbbackend.azurewebsites.net/getmacdetails?applicationName=${encodeURIComponent(
          applicationName
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      setMacDetails(data);
      console.log("MAC Details", data);
    } catch (error) {}
  };

  const getCertificationPercentage = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/getcertificationpercentage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
          }),
        }
      );
      const result = await response.json();
      // //console.log("Certification Percentage", result);
      await setcertifypercentage(result.percentage);
      await setappenv(result.environment[0]);
      //console.log(typeof result.environment[0]);
      // props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // props.setProgress(100);
    }
  };

  async function showAppToAppCertifyButton(application) {
    try {
      // //console.log(application);
      setrelationcertifiedapptoapp(false);
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/showcertify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: application,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_app",
          }),
        }
      );
      const result = await response.json();
      //console.log("data", result);
      const userCheckResult = result.user_check;
      // //console.log(userCheckResult);
      const pendingRequestResult = result.pending_request;
      // //console.log(pendingRequestResult);
      const certificationResult1 = result.certification;
      // //console.log(certificationResult1);
      if (
        userCheckResult == "invalid" &&
        pendingRequestResult == "invalid" &&
        certificationResult1 == "invalid"
      ) {
        setDisableCertify1apptoapp(true);
      } else {
        if (userCheckResult === "Verified") {
          
          if (pendingRequestResult === "Pending request") {
            setDisableCertify1apptoapp(true);
            setShowTooltip1apptoapp(
              "Please clear the pending requests to certify the application"
            );
            if (certificationResult1 !== "Certify") {
              setShowTooltip2apptoapp(certificationResult1.tooltip1);
              setrelationcertifiedapptoapp(true);
            }
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 === "Certify"
          ) {
            //console.log("Certify button enabled");
            setDisableCertify1apptoapp(false);
            setShowTooltip1apptoapp(
              "As Application Manager, you can review the application to server relationships and validate by clicking the Certify button at every 90 days"
            );
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 !== "Certify"
          ) {
            if (certificationResult1 == "Not in Production") {
              setrelationcertifiedapptoapp(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertifiedapptoapp(true);
            }
            setShowTooltip2apptoapp(certificationResult1.tooltip1);
            setDisableCertify1apptoapp(true);
            if (
              certificationResult1.tooltip2.includes("Certification expire")
            ) {
              setDisableCertify1apptoapp(false);
            }
            setShowTooltip1apptoapp(
              certificationResult1 && certificationResult1.tooltip2
                ? certificationResult1.tooltip2
                : "Unknown error occurred"
            );
          }
        }
        if (userCheckResult === "Not Verified") {
          if (certificationResult1 == "Certify") {
            setrelationcertifiedapptoapp(false);
          }
          if (certificationResult1 != "Certify") {
            if (certificationResult1 == "Not in Production") {
              setrelationcertifiedapptoapp(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertifiedapptoapp(true);
            }
            setShowTooltip2apptoapp(certificationResult1.tooltip1);
          }
          // setrelationcertified(false);
          setDisableCertify1apptoapp(true);
          setShowTooltip1apptoapp(
            "You need to be the Application Manager to certify"
          );
        } 
        if (certificationResult1 == "retired"){
          setDisableCertify1apptoapp(true);
          setrelationcertifiedapptoapp(false);
          setShowTooltip1apptoapp("Application is retired");
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  async function showRelationshipCertifyButton(application) {
    try {
      //console.log(application);
      setrelationcertified(false);
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/showcertify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: application,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_server",
          }),
        }
      );
      const result = await response.json();
      //console.log("data", result);
      const userCheckResult = result.user_check;
      // //console.log(userCheckResult);
      const pendingRequestResult = result.pending_request;
      // //console.log(pendingRequestResult);
      const certificationResult1 = result.certification;
      // //console.log(certificationResult1);
      if (
        userCheckResult == "invalid" &&
        pendingRequestResult == "invalid" &&
        certificationResult1 == "invalid"
      ) {
        setDisableCertify1(true);
      } else {
        if (userCheckResult === "Verified") {
          

          if (pendingRequestResult === "Pending request") {
            setDisableCertify1(true);
            setShowTooltip1(
              "Please clear the pending requests to certify the application"
            );
            
            if (certificationResult1 === "Certify") {
              setrelationcertified(false);
            }
            if (certificationResult1 !== "Certify") {
              setShowTooltip2(certificationResult1.tooltip1);
              setrelationcertified(true);
            }
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 === "Certify"
          ) {
            //console.log("Certify button enabled");
            setDisableCertify1(false);
            setShowTooltip1(
              "As Application Manager, you can review the application to server relationships and validate by clicking the Certify button at every 90 days"
            );
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 !== "Certify"
          ) {
            if (certificationResult1 == "Not in Production") {
              setrelationcertified(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertified(true);
            }
            setShowTooltip2(certificationResult1.tooltip1);
            setDisableCertify1(true);
            if (
              certificationResult1.tooltip2.includes("Certification expire")
            ) {
              setDisableCertify1(false);
            }
            setShowTooltip1(
              certificationResult1 && certificationResult1.tooltip2
                ? certificationResult1.tooltip2
                : "Unknown error occurred"
            );
          }
        }
        if (userCheckResult === "Not Verified") {
          if (certificationResult1 == "Certify") {
            setrelationcertified(false);
          }
          if (certificationResult1 != "Certify") {
            if (certificationResult1 == "Not in Production") {
              setrelationcertified(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertified(true);
            }
            setShowTooltip2(certificationResult1.tooltip1);
          }
          // setrelationcertified(false);
          setDisableCertify1(true);
          setShowTooltip1("You need to be the Application Manager to certify");
        }
        if (certificationResult1 == "retired"){
          setDisableCertify1(true);
          setrelationcertified(false);
          setShowTooltip1("Application is retired");
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }
  const handleSaveClick = async () => {
    if (
      tempData.owned_by.displayValue == "" ||
      tempData.managed_by.displayValue == ""
    ) {
      toast.error("Please fill  the required fields", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setshowbuttons(false);

    props.setProgress(10);
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/sendownershipdata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            originaldata: ownershipdata,
            updateddata: tempData,
            requestor: graphData?.displayName,
          }),
        }
      );
      props.setProgress(50);
      //console.log("response", response);

      await getOwnershipInfo(certifyapplicationName);
      toast.success("Ownership data saved successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Error saving data:", error);
        toast.error("Error Occured", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      props.setProgress(100);
    }
  };
  const handleDiscardClick = () => {
    setDropdownStates((prevState) => {
      const newState = {};
      for (let key in prevState) {
        newState[key] = true;
      }
      return newState;
    });

    const elements1 = document.querySelectorAll(".suggestion-container3");
    //console.log(elements1);
    elements1.forEach((element) => {
      element.style.display = "none";
    });
    const elements2 = document.querySelectorAll(".search-bar");
    //console.log(elements2);
    elements2.forEach((element) => {
      element.style.borderBottomLeftRadius = "5px";
    });

    const elements3 = document.querySelectorAll(".search-icon");
    //console.log(elements3);
    elements3.forEach((element) => {
      element.style.borderBottomRightRadius = "5px";
    });
    setTempData({ ...ownershipdata });
    setEditable(false);
  };
  const handleOwnershipCertification = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/certifyownership",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            logged_in_user: graphData?.displayName,
          }),
        }
      );
      const data = await response.json();
      props.setProgress(50);
      if (data.message === "Certified") {
        await fetchOwnership();
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableCertify1(true);//
        await getCertificationPercentage(certifyapplicationName);
        await getCertifyTooltip(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const handleiconclick = async (e, category) => {
    if (!lastUpdatedField) return;
    if (readOnly) {
      const category = lastUpdatedField;
      const searchbox = document.getElementById("search-icon");
      //console.log(searchbox);
      // setDropdownStates({
      //   ...dropdownStates,
      //   [e.target.id]: false,
      // });

      //console.log("clicked", e.target.id);
      const elements = document.getElementsByName(category);
      //console.log(elements);
      const searchBox = document.getElementsByName(category)[0];
      const suggestionBox = document.getElementsByName(category)[2];
      const searchicon = document.getElementsByName(category)[1];
      const elements1 = document.querySelectorAll(".suggestion-container3");
      //console.log(elements1);
      elements1.forEach((element) => {
        element.style.display = "none";
      });
      const elements2 = document.querySelectorAll(".search-bar");
      //console.log(elements2);
      elements2.forEach((element) => {
        element.style.borderBottomLeftRadius = "5px";
      });

      const elements3 = document.querySelectorAll(".search-icon");
      //console.log(elements3);
      elements3.forEach((element) => {
        element.style.borderBottomRightRadius = "5px";
      });

      const field = lastUpdatedField;
      const searchTerm = tempData[category]?.displayValue;

      console.log(searchTerm.length);
      if (searchTerm.length == 0) {
        suggestionBox.style.display = "none";
        suggestionBox.innerHTML = "";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px";
      }
      if (searchTerm.length >= 3) {
        console.log(searchTerm);

        suggestionBox.style.display = "block";

        setDropdownStates((prevState) => {
          const newState = {};
          for (let key in prevState) {
            if (key === category) {
              newState[key] = false;
            } else {
              newState[key] = true;
            }
          }
          return newState;
        });
        searchBox.style.borderBottomLeftRadius = "0%";
        searchicon.style.borderBottomRightRadius = "0%";
        suggestionBox.innerHTML = "<div>Loading...</div>";
        await fetch(
          `https://prod-cmdbbackend.azurewebsites.net/fetch_user_data?UserName=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            if (searchTerm.trim() === "") {
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              return;
            }
            // Display a maximum of 20 suggestions
            // const maxSuggestions = 20;
            for (let i = 0; i < data.length; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              // suggestionItem.textContent = data[i];
              // suggestionItem.innerHTML = "";
              //console.log(data[i]);
              //console.log(data[i].name);

              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } <br> ${data[i].email == undefined ? "" : data[i].email}`;
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setTempData({
                    ...tempData,
                    [field]: {
                      displayValue: data[i].name,
                      value: data[i].sys_id,
                      email: data[i].email, // Adding sysid as empty string as per the schema
                    },
                  });
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchicon.style.borderBottomRightRadius = "5px";
                  setDropdownStates((prevState) => {
                    const newState = {};
                    for (let key in prevState) {
                      newState[key] = true;
                    }
                    return newState;
                  });
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0) {
              suggestionBox.style.display = "block";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchicon.style.borderBottomRightRadius = "0%"; // Show the suggestion box
            } else {
              setDropdownStates((prevState) => {
                const newState = {};
                for (let key in prevState) {
                  if (key === category) {
                    newState[key] = true;
                  } else {
                    newState[key] = false;
                  }
                }
                return newState;
              });
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchicon.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      if (searchTerm.length == 0) {
        setDropdownStates({
          ...dropdownStates,
          [category]: true,
        });
        console.log("No data");
        while (suggestionBox.firstChild) {
          suggestionBox.removeChild(suggestionBox.firstChild);
        }
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    }
  };
  const getCertifyTooltip = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        "https://prod-cmdbbackend.azurewebsites.net/getownercertifytooltip",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
          }),
        }
      );
      const result = await response.json();
      if (result == "Not Certified") {
        setownershipcertified(false);
        setownershipTooltip(
          "As an owner, you can certify ownership of the application"
        );
      } else if (result == "retired") {
        setownershipcertified(false);
        setownershipTooltip(
          "App is retired"
        );
        // setisciadmingrp(true);
      }
      
      else {
        setownershipcertified(true);
        setownershipTooltip(result);
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  return (
    <div>
      <div>{isciadmingrp}</div>
      <div className="container3">
        <div className="row" style={{ height: "40px" }}>
          <div className="col-md-8">Select an application</div>
          <div
            className="col-md-4"
            style={{
              overflow: "hidden",
            }}
          >
            <div style={{ width: "300px" }}>
              <input
                id="search-bar2"
                name="search-bar2"
                style={{ width: "100%" }}
                type="text"
                onChange={handleapplicationNameChange}
                value={certifyapplicationName}
                autoComplete="off"
              ></input>
              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "relative",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div 
        className="row"
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   margin: "12px",
          //   borderbottom: "inset",
          // }}
        >
          <span style={{width:"fit-content"}}>Application ID : {macDetails?.applicationId}</span>
          <span style={{width:"fit-content"}}>Platform Name : {macDetails?.platformName}</span>
          <span style={{width:"fit-content"}}>Portfolio Name : {macDetails?.portfolioName}</span>
        </div>
        <div className="row">
          <div className="col-md-8">Certification Percentage</div>
          <div className="col-md-4 text-right">
            <span
              style={{
                color:
                  certifypercentage === 100
                    ? "green"
                    : certifypercentage === 50
                    ? "orange"
                    : certifypercentage === 75
                    ? "#FFBF00"
                    : certifypercentage === ""
                    ? "black"
                    : "red",
              }}
              id="percentage"
            >
              {certifypercentage?.length == 0 ? "0%" : certifypercentage + "%"}
              {/* {certifypercentage + "%"} */}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            App to Server Relationships
            <span
              style={{
                marginLeft: "3px",
              }}
            >
              {relationcertified ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {/* <FcApproval size={20} />
                   */}
                  {showTooltip2?.includes("Last Certified") ? (
                    <FaExclamationCircle
                      size={20}
                      style={{
                        color: "#ffc107",
                        marginRight: "2px",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  &nbsp;
                  {showTooltip2}
                </div>
              ) : null}
            </span>
          </div>
          <div className="col-md-4">
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful1 ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify1}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify1 } ? "not-allowed" : "pointer",
                  }}
                >
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="View"
                    name="primary"
                    onClick={function noRefCheck() {
                      if (certifyapplicationName == "") {
                        toast.error("Please select an application", {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      } else {
                          setapplicationName(certifyapplicationName);
                          setRelationshipCategorySelected(
                            relationshipCategory[1]
                          );
                          navigate("/main");
                          setautosearch(true);
                          setSelectedSuggestion(true);
                        // }
                      }
                    }}
                    title="Button"
                    variant="filled"
                    style={{ width: "80px" }}
                  />
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="Certify"
                    name="primary"
                    disabled={disableCertify1}
                    onClick={function noRefCheck() {
                        handleRelationshipCertify();
                    }}
                    title="Button"
                    variant="filledAlt"
                    style={{ marginLeft: "10px" }}
                  />
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="info"
                  data-tooltip-content={showTooltip1}
                  // data-tooltip-hidden={appenv == "Production" ? false : true}
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="info" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            App to App Relationships
            <span
              style={{
                marginLeft: "3px",
              }}
            >
              {relationcertifiedapptoapp ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {/* <FcApproval size={20} /> */}
                  {showTooltip2apptoapp?.includes("Last Certified") ? (
                    <FaExclamationCircle
                      size={20}
                      style={{
                        color: "#ffc107",
                        marginRight: "2px",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  &nbsp;
                  {showTooltip2apptoapp}
                </div>
              ) : null}
            </span>
          </div>
          <div className="col-md-4">
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful1 ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify1apptoapp}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify1apptoapp }
                      ? "not-allowed"
                      : "pointer",
                  }}
                >
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="View"
                    name="primary"
                    onClick={function noRefCheck() {
                      if (certifyapplicationName == "") {
                        toast.error("Please select an application", {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      } else {
                        if (appenv != "Production") {
                          toast.error(
                            <>
                              This feature is only available for Production
                              Application for now.
                              <br />
                              Current environment: {appenv}
                            </>,
                            {
                              position: "top-center",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        } else {
                          setapplicationName(certifyapplicationName);
                          setRelationshipCategorySelected(
                            relationshipCategory[2]
                          );
                          navigate("/main");
                          setautosearch(true);
                          setSelectedSuggestion(true);
                        }
                      }
                    }}
                    title="Button"
                    variant="filled"
                    style={{ width: "80px" }}
                  />
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="Certify"
                    name="primary"
                    disabled={disableCertify1apptoapp}
                    onClick={function noRefCheck() {
                      if (appenv == "Production") {
                        handleAppToAppRelationshipCertify();
                        // handleRelationshipCertify();
                      } else {
                        toast.error(
                          <>
                            This feature is only available for Production
                            Application for now.
                            <br />
                            Current environment: {appenv}
                          </>,
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      }
                    }}
                    title="Button"
                    variant="filledAlt"
                    style={{ marginLeft: "10px" }}
                  />
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="infoapptoapp"
                  data-tooltip-content={showTooltip1apptoapp}
                  data-tooltip-hidden={appenv == "Production" ? false : true}
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="infoapptoapp" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="accordion">
            <div
              style={{
                display: "inline-block",
                verticalAlign: "-0.7em",
              }}
            >
              App Ownership
              <span
                style={{
                  marginLeft: "3px",
                }}
              >
                {ownershipcertified ? (
                  <div
                    style={{
                      display: "inline",
                      fontSize: "12px",
                    }}
                  >
                    {/* <FcApproval size={20} /> */}
                    
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                     &nbsp;
                    {ownershipTooltip}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                float: "right",
                display: "flex",
              }}
            >
              <Button
                ref={inputRef}
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label={isViewOpen ? "Hide" : "View"}
                name="primary"
                onClick={() => {
                  if (certifyapplicationName != "" || isViewOpen) {
                    handleAccordionclick();
                  } else {
                    toast.error("Please select an application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                }}
                title="Button"
                variant="filled"
                style={{ width: "80px" }}
              />
              <Button
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label="Certify"
                name="primary"
                onClick={function noRefCheck() {
                  handleOwnershipCertification();
                }}
                title="Button"
                variant="filledAlt"
                disabled={!isciadmingrp}
                style={{ marginLeft: "10px" }}
              />
              <div
                data-tooltip-id="info1"
                data-tooltip-hidden={certifyapplicationName ? false : true}
                data-tooltip-content={
                  isciadmingrp
                    ? ownershipTooltip.includes("retired") ? "App is retired" : ownershipTooltip
                    : ownershipTooltip.includes("retired") ? "App is retired" : 
                    "You need to be an owner to certify"
                }
                data-tooltip-place="top-end"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="info1" />
            </div>
          </div>
          <div className="panel">
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Service Owner :
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                  }}
                >
                  &#42;
                </span>
                <div
                data-tooltip-id="service_owner"
                data-tooltip-content="The Service Owner is the main contact for the applications owned, ensuring the CMDB is accurate for key stakeholders. The SO reviews and approves changes, manages major incidents, and coordinates with functional teams like Vendor, Risk, and Audits."
                data-tooltip-place="top-start"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="service_owner" style={{width:"40vw"}}/>

              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="owned_by"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      value={
                        readOnly
                          ? tempData.owned_by.displayValue
                          : ownershipdata.owned_by.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "owned_by");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="owned_by"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: readOnly ? "default" : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.owned_by ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="owned_by"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="owned_by"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Senior Service Owner :
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                  }}
                >
                  &#42;
                </span>
                <div
                data-tooltip-id="senior_service_owner"
                data-tooltip-content="The Senior Service Owner has similar responsibilities to the SO but acts as the next escalation point if the SO is unavailable."
                data-tooltip-place="top-start"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="senior_service_owner" style={{width:"40vw"}}/>
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="managed_by"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.managed_by.displayValue
                          : ownershipdata.managed_by.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "managed_by");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="managed_by"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        cursor: readOnly ? "default" : "not-allowed",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.managed_by ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="managed_by"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="managed_by"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Engineering Manager :
                <div
                  data-tooltip-id="engineering_manager"
                  data-tooltip-content="The Engineering Manager may also serve as a Service Owner, promoting CMDB data governance and co-owning processes such as Change and Incident Management within the team."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_manager" style={{width:"40vw"}}/>
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_engineering_manager"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_engineering_manager.displayValue
                          : ownershipdata.u_engineering_manager.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_engineering_manager");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_engineering_manager"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        cursor: readOnly ? "default" : "not-allowed",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_engineering_manager ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_engineering_manager"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_engineering_manager"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Senior Engineering Manager :
                <div
                  data-tooltip-id="senior_engineering_manager"
                  data-tooltip-content="The Senior Engineering Manager has responsibilities similar to the EM but acts as the next escalation point if the EM is unavailable."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="senior_engineering_manager" style={{width:"40vw"}}/>

              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_sr_engineering_manager"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_sr_engineering_manager.displayValue
                          : ownershipdata.u_sr_engineering_manager.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_sr_engineering_manager");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      name="u_sr_engineering_manager"
                      className="search-icon"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        cursor: readOnly ? "default" : "not-allowed",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_sr_engineering_manager ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_sr_engineering_manager"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_sr_engineering_manager"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Engineering Director :
                <div
                  data-tooltip-id="engineering_director"
                  data-tooltip-content="The Engineering Director (ED) oversees multiple Engineering Managers, sets technical guardrails, drives engineering consistency, manages the technical improvement roadmap, ensures cost-effectiveness, and adopts industry-standard practices."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_director" style={{width:"40vw"}}/>
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_engineering_director"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_engineering_director.displayValue
                          : ownershipdata.u_engineering_director.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_engineering_director");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_engineering_director"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        cursor: readOnly ? "default" : "not-allowed",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_engineering_director ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_engineering_director"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_engineering_director"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                float: "left",
                margin: "10px",
                marginLeft: "20px",
                marginTop: "20px",
                fontSize: "0.8em",
              }}
            >
              {isciadmingrp && (!ownershipTooltip.includes("retired")) && (
                <span>
                  Note: Please search for a name and select from dropdown
                </span>
              )}
            </div>
            {isciadmingrp && (!ownershipTooltip.includes("retired")) && (
              <div
                style={{
                  margin: "10px",
                  float: "right",
                  height: "40px",
                  marginRight: "20px",
                }}
              >
                <Button
                  appearance="alt"
                  fit="medium"
                  id="primary"
                  justifyItems="center"
                  label="Edit"
                  name="primary"
                  onClick={function noRefCheck() {
                    setTempData({ ...ownershipdata });
                    setReadOnly(true);
                    setshowbuttons(true);
                  }}
                  title="Button"
                  variant="filled"
                  style={{
                    width: "80px",
                    display: showbuttons ? "none" : "block",
                  }}
                />
                {showbuttons && (
                  <div style={{ display: "inline" }}>
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Save"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleSaveClick();
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Discard"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleDiscardClick();
                        setshowbuttons(false);
                        setReadOnly(false);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px", visibility: "hidden" }}>""</div>
      {/* <button onClick={()=>{
        navigate("/test")
      }}>
        Click me
      </button>
      <div>{tempData.owned_by?.value}</div>
      <div>
        <div>Values: {tempData.owned_by.displayValue}</div>
        <div>Values: {tempData.managed_by.displayValue}</div>
        <div>Values: {tempData.u_engineering_manager.displayValue}</div>
        <div>Values: {tempData.u_sr_engineering_manager.displayValue}</div>
        <div>Values: {tempData.u_engineering_director.displayValue}</div>
      </div>
      <div>
        <div>{ownershipdata.sys_id}</div>
        <div>Email : {ownershipdata.managed_by.email}</div>
        <div>{ownershipdata.owned_by.displayValue}</div>
        <div>{ownershipdata.managed_by.displayValue}</div>
        <div>{ownershipdata.u_engineering_manager.displayValue}</div>
        <div>{ownershipdata.u_sr_engineering_manager.displayValue}</div>
        <div>{ownershipdata.u_engineering_director.displayValue}</div>
      </div> */}
    </div>
  );
}
export default Certification;
