import {
  React,
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import "../App.css";
import { Link, useLocation } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import { TableContext } from "../context";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import Maintainance from "./Maintainance";
/**
 * Represents the navigation bar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered Navbar component.
 */
export default function Navbar(props) {
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { graphData } = useContext(TableContext);
  const {
    table1,
    setTable1,
    applicationName,
    setapplicationName,
    setDeletedRows,
    showCommit,
    setshowCommit,
    handleLogout,
    isOwner,
    count,
    getCount,
  } = useContext(TableContext);
  // console.log(location);
  const dropdownRef = useRef(null);
  /**
   * Toggles the dropdown open or closed.
   */
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  /**
   * Closes the dropdown.
   */
  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  /**
   * Handles the click outside event for the dropdown.
   * Closes the dropdown if the click is outside the dropdown element.
   *
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleDropdownClose();
    }
  };
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    // Cleanup function for the effect hook
    return () => {
      // Remove the event listener for mousedown
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // This effect hook runs when isDropdownOpen changes
  }, [isDropdownOpen]);

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      // Remove event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /**
   * Handles the logout action.
   */
  const handleLogout1 = () => {
    setTable1([]);
    // setGraphData(null)
    setapplicationName("");
    handleLogout();
  };
  // If the current path is /signin or *, return null
  if (location.pathname === "/" || location.pathname === "*") return null;
  // Otherwise, render the navbar
  else
    return (
      <div>
        <nav className="navbar">
          <div className="nav-bar">
            <img
              src="./images/Maersk_Logo_RGB.svg"
              alt=" "
              height="50"
              width="120"
              style={{}}
            />
            <span className="logo navLogo">
              <Link to="/">CMDB Portal</Link>
              <p></p>
            </span>
            <div className="menu">
              {/* <div className="logo-toggle">
                <span className="logo">
                  <a href="/">CMDB Portal</a>
                </span>
                <i className="bx bx-x siderbarClose"></i>
              </div> */}
              <ul className="nav-links">
                <li>
                  <Link to="/main"> Search Relationship</Link>
                </li>
                <li>
                  <Link className="viewchanges" to="/ViewChanges">
                    View Proposed Relationships
                  </Link>
                </li>
                {isOwner && (
                  <li className="approvechanges">
                    <Link to="/ApproveChanges">
                      Approve Proposed Relationships
                    </Link>
                    <div>
                      <NotificationBadge
                        count={count}
                        style={{
                          position: "absolute",
                          right: "-14px",
                          top: "-3px",
                        }}
                      />
                    </div>
                    {/* <NotificationBadge count={10} /> */}
                  </li>
                )}
                <li className="certify">
                  <Link to="/certify">Certification</Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      props.setRun(!props.run);
                    }}
                  >
                    Take a tour
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            ref={dropdownRef}
            className="user-profile"
            onClick={handleDropdownToggle}
          >
            <span>{graphData?.displayName}</span>
            <ProfilePicture
              initials={graphData?.displayName
                .match(/(^\S\S?|\b\S)?/g)
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toUpperCase()}
              size={35}
            />
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <ul>
                  <li>
                    <Link to="/ViewChanges">View Proposed Relationships</Link>
                  </li>
                  {isOwner && (
                    <li>
                      <Link to="/ApproveChanges">
                        Approve Proposed Relationships
                      </Link>
                    </li>
                  )}
                   <li>
                      <Link to="/certify">Certification</Link>
                    </li>
                  <li>
                    <Link onClick={handleLogout1}>Logout</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
{/* <Maintainance top={50} /> */}
      </div>
    );
}
