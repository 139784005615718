import React from "react";
import { Icon } from "@anchor/react-components";
import {
  FaCross,
  FaRegTimesCircle,
  FaThumbsUp,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { TableContext } from "../context";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
const Modal = ({ isOpen, onClose, setOpen }) => {
  const { graphData, fetchWithTimeout } = useContext(TableContext);
  if (!isOpen) return null;
  /**
   * Handles the click event and sends a rating to the server.
   * @param {number} rating - The rating value.
   * @returns {Promise<void>} - A Promise that resolves when the request is completed.
   */
  const handleClick = async (rating) => {
    try {
      const response = await fetchWithTimeout(`https://prod-cmdbbackend.azurewebsites.net/rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ rating: rating, user: graphData.displayName }),
      });
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  return (
    <div
      // onClick={onClose}
      style={{
        position: "absolute",
        top: 0,
        // left: 0,
        bottom: 0,
        width: "100%",
        height: document.body.scrollHeight,
        overflowY: "auto",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "50%",
          transform: "translate(50%, -50%)",
          textAlign: "center",
          paddingTop: "40px",
          background: "white",
          height: 200,
          width: 400,
          margin: "auto",
          padding: "20px",
          // border: "2px solid #000",
          borderRadius: "10px",
          // borderTopRightRadius: "0px",
          // borderBottomRightRadius: "0px",
          boxShadow: "2px solid black",
        }}
      >
        <FaTimes
          className="close"
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            margin: "15px",
            cursor: "pointer",
          }}
          size={23}
          onClick={() => {
            setOpen(false);
          }}
        />
        <span>Would you mind rating us</span>
        <div className="rating">
          {/* <!-- Thumbs up --> */}
          <div className="like grow">
            <FaThumbsUp
              name="thumbs-up"
              size={40}
              style={{ marginRight: "20px" }}
              onClick={async () => {
                // const response=await fetchWithTimeout(`https://prod-cmdbbackend.azurewebsites.net/rating?rating=1`)
                handleClick("1");
                console.log("1");
                setOpen(false);
              }}
            />
          </div>
          {/* <!-- Thumbs down --> */}
          <div className="dislike grow">
            <FaThumbsDown
              name="thumbs-down"
              size={40}
              style={{ marginLeft: "20px" }}
              onClick={() => {
                handleClick("0");
                console.log("0");
                setOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
